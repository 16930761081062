<template>
  <b-card class="mx-2 mt-2">
    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <div class="search-results">
                {{ totalProducts }} results found
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <b-row class="mt-2">
      <!-- Overlay -->
      <b-col
        cols="12"
        xl="3"
        md="3"
      >
        <b-row>
          <b-col cols="6">
            <h6 class="filter-heading d-none d-lg-block">
              Filters
            </h6>
          </b-col>
        </b-row>

        <!-- Filters' Card -->
        <b-card>
          <!-- Categories -->
          <div class="product-categories">
            <h6
              class="filter-title"
              style="font-weight: 600"
            >
              Categories
            </h6>
            <b-form-radio-group
              v-model="filters.categories"
              class="categories-radio-group mt-1"
              stacked
              :options="filterOptions.categories"
            />
          </div>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        xl="9"
        md="9"
      >
        <!-- Searchbar -->
        <div class="ecommerce-searchbar mt-1">
          <b-row>
            <b-col cols="12">
              <b-input-group class="input-group-merge">
                <b-form-input
                  placeholder="Search Resources"
                  class="search-product"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="SearchIcon"
                    class="text-muted"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </div>

        <!-- Prodcuts -->
        <section
          class="mt-2"
          :class="itemView"
        >
          <b-row>
            <b-col
              v-for="resource in resources"
              :key="resource.id"
              cols="6"
              sm="6"
              md="6"
              xl="4"
            >
              <b-card
                class="ecommerce-card"
                no-body
              >
                <div class="item-img text-center">
                  <b-link>
                    <b-img
                      :alt="`${resource.name}-${resource.id}`"
                      fluid
                      style="height: 250px; width: 400px"
                      class="card-img-top"
                      :src="resource.image"
                    />
                  </b-link>
                </div>
                <!-- resource Details -->
                <b-card-body>
                  <h6 class="item-name">
                    <b-link
                      class="text-body"
                      :to="{ path: resource.url }"
                    >
                      {{ resource.name }}
                    </b-link>
                    <b-card-text class="item-company">
                      By {{ resource.by }}
                    </b-card-text>
                  </h6>
                  <b-card-text class="item-description">
                    {{ resource.description }}
                  </b-card-text>
                </b-card-body>

                <!-- resource Actions -->
                <div class="item-options text-center">
                  <b-button
                    variant="primary"
                    tag="a"
                    class="w-100"
                    @click="$router.push(resource.url)"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      class="mr-50"
                    />
                    <span>View More</span>
                  </b-button>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </b-col>
    </b-row>
    <ProfileCompletionForm
      :activate-popup="popupProfileQuestions"
      :hide-header="true"
      @open-completion-form="activateTypeForm"
    />
  </b-card>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BCardText,
  BButton,
  BPagination,
  BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { completionMixins } from '@/components/mixins/CompletionMixins'
import LeftFilterSidebar from './LeftFilterSidebar.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    LeftFilterSidebar,
  },

  mixins: [completionMixins],
  data() {
    return {
      totalProducts: 2,
      resources: [],
      itemView: 'grid-view',
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      filters: {
        q: '',
        categories: [],
        page: 1,
        perPage: 9,
      },

      filterOptions: {
        categories: ['Documentation', 'Training'],
      },
      mqShallShowLeftSidebar: false,
    }
  },
  async created() {
    this.resources = [
      {
        name: 'User Manual',
        by: 'HeadHunters HQ',
        email: 'career@headhuntershq.com',
        description: '',
        image: await this.$generateDownloadUrl('media/black_hq_logo.png'),
        id: '1',
      },
      // {
      //   name: 'Interview Training Video', by: 'Alvigor', description: '', image: `${this.$http.defaults.baseURL}/api/media/employer_training.jpg`, id: '2',
      // },
      {
        name: 'Interview Training Video',
        by: 'Alvigor',
        email: 'jobsecurity@e2i.com.sg',
        description: '',
        image: await this.$generateDownloadUrl('media/applicant_training.jpg'),
        id: '3',
      },
    ]
  },
  mounted() {
    const incompleteProfile = this.checkProfileCompletion()

    // this.$generatePresignedUrl('event-images/uuid-filename.jpg')
    //   .then(response => {
    // })

    if (incompleteProfile) {
      this.popupProfileQuestions = true
    }

    this.initComponent()
  },
  methods: {
    async initComponent() {
      const { account_type } = this.$store.state.auth.ActiveUser
      if (account_type === this.$AccountTypes.APPLICANT) {
        this.resources = [
          {
            url: '/resources/user-guide',
            name: 'User Manual',
            by: 'HeadHunters HQ',
            description: '',
            image: await this.$generatePresignedUrl('media/black_hq_logo.png'),
            id: '1',
          },
          // {
          //   name: 'Interview Training Video', by: 'Alvigor', description: '', image: `${this.$http.defaults.baseURL}/api/media/employer_training.jpg`, id: '2',
          // },
          {
            url: '/resources/training',
            name: 'Interview Training Video',
            by: 'Alvigor',
            description: '',
            image: await this.$generatePresignedUrl('media/applicant_training.jpg'),
            id: '3',
          },
        ]
      } else if (
        account_type === this.$AccountTypes.AGENCY || account_type === this.$AccountTypes.EMPLOYER
      ) {
        this.resources = [
          {
            url: '/resources/user-guide',
            name: 'User Manual',
            by: 'HeadHunters HQ',
            description: '',
            image: await this.$generatePresignedUrl('media/black_hq_logo.png'),
            id: '1',
          },
          {
            url: '/resources/training',
            name: 'Interview Training Video',
            by: 'Alvigor',
            description: '',
            image: await this.$generatePresignedUrl('media/employer_training.jpg'),
            id: '2',
          },
        ]
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
